<template>
  <d-medical-devices-control :role="role" />
</template>

<script>

export default {
  name: 'MedicalDevicesControl',
  page: {
    title: 'CRM Doctis - Выдача и возврат приборов',
  },
  computed: {
    role() {
      return this.$store.state.Auth.role;
    },
  },
};
</script>
